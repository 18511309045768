<template>
  <div>
    <c-box
      as="nav"
      display="flex"
      gap="16px"
    >
      <c-box
        as="button"
        height="25px"
        width="25px"
        min-width="25px"
        border-radius="100%"
        :background-color="color === 'primary' ? 'primary.50' : 'neutral.superLightGray'"
        display="flex"
        justify-content="center"
        align-items="center"
        :_hover="{ filter: 'brightness(0.9)' }"
        :_active="{ filter: 'brightness(0.8)' }"
        @click="handlePrevPage"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-chevron-left.svg')"
          height="80%"
          width="80%"
          stroke="#008C81"
        />
      </c-box>
      <c-box
        as="ul"
        display="flex"
        list-style-type="none"
        gap="16px"
      >
        <c-box
          v-for="i in [...firstPagination, ...middlePagination, ...lastPagination]"
          :key="i === '...' ? Math.random() : i"
          as="li"
        >
          <c-box
            v-if="i === '...'"
            height="24px"
            width="24px"
            display="flex"
            justify-content="center"
            align-items="center"
          >
            {{ i }}
          </c-box>
          <c-button
            v-else
            :background-color="currentPage === i ? (color === 'primary' ? 'superLightGray.900' : 'secondary.400') : 'inherit'"
            :_hover="{
              backgroundColor: 'secondary.200'
            }"
            :_active="{
              backgroundColor: 'secondary.300'
            }"
            font-size="14px"
            font-weight="400"
            height="24px"
            width="24px"
            padding="0"
            min-width="24px"
            border-radius="100%"
            display="flex"
            justify-content="center"
            align-items="center"
            @click="handlePageClick(i)"
          >
            {{ i }}
          </c-button>
        </c-box>
      </c-box>
      <c-box
        as="button"
        height="25px"
        width="25px"
        min-width="25px"
        border-radius="100%"
        :background-color="color === 'primary' ? 'primary.50' : 'neutral.superLightGray'"
        display="flex"
        justify-content="center"
        align-items="center"
        :_hover="{ filter: 'brightness(0.9)' }"
        :_active="{ filter: 'brightness(0.8)' }"
        @click="handleNextPage"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-chevron-right.svg')"
          height="80%"
          width="80%"
          stroke="#008C81"
        />
      </c-box>
    </c-box>
  </div>
</template>

<script>
import { brightness } from 'tailwindcss/lib/plugins'

export default {
  name: 'BasePagination',
  props: {
    page: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 13,
    },
    color: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary'].includes(value)
      },
    },
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  computed: {
    totalRangeArray() {
      return Array.from({ length: this.totalPage }, (_, i) => i + 1)
    },
    firstPagination() {
      if (this.totalPage <= 7) return this.totalRangeArray
      if (this.currentPage <= 3) return this.totalRangeArray.slice(0, 3)
      if (this.totalPage - 3 < this.currentPage) return this.totalRangeArray.slice(0, 3)
      return this.totalRangeArray.slice(0, 1)
    },
    middlePagination() {
      const range = this.totalRangeArray.slice(3, -3)

      if (this.totalPage <= 7) return []
      if (this.currentPage <= 3) return ['...']
      if (this.totalPage - 3 < this.currentPage) return ['...']
      if (this.currentPage === range[0]) return ['...', ...this.totalRangeArray.slice(this.currentPage-1, this.currentPage-1 + 3), '...']
      if (this.currentPage === range[range.length-1]) return ['...', ...this.totalRangeArray.slice(this.currentPage-3, this.currentPage-3 + 3), '...']
      return ['...', ...this.totalRangeArray.slice(this.currentPage-2, this.currentPage-2 + 3), '...']
      // return range
      // return []
    },
    // 12 >= 4
    lastPagination() {
      if (this.totalPage <= 7) return []
      if (this.currentPage <= 3) return this.totalRangeArray.slice(-3)
      if (this.totalPage - 3 < this.currentPage) return this.totalRangeArray.slice(-3)
      return this.totalRangeArray.slice(-1)
    },
  },
  watch: {
    page: {
      immediate: true,
      handler(page) {
        this.currentPage = page
      },
    },
  },
  methods: {
    brightness() {
      return brightness
    },
    handleNextPage() {
      if (this.currentPage === this.totalPage) return
      this.currentPage += 1
      this.handleChange()
    },
    handlePrevPage() {
      if (this.currentPage === 1) return
      this.currentPage -= 1
      this.handleChange()
    },
    handlePageClick(page) {
      this.currentPage = page
      this.handleChange()
    },
    handleChange() {
      this.$emit('on-change', this.currentPage)
    },
  },
}
</script>
